.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  path {
    fill: "#ff0000"!important;
  }
}

body {
  background-color: #282c34;
}

@media (prefers-reduced-motion: no-preference) {
  /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
}

.App-header {
  background-color: #282c34;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face { 
  font-family: "RubikPuddles-Regular"; 
  src: url("./assets/fonts/RubikPuddles-Regular.ttf") format("truetype") 
}

@font-face { 
  font-family: "MoiraiOne-Regular"; 
  src: url("./assets/fonts/MoiraiOne-Regular.ttf") format("truetype") 
}

@font-face { 
  font-family: "FasterOne-Regular";
  src: url("./assets/fonts/FasterOne-Regular.ttf") format("truetype") 
}

@font-face { 
  font-family: "Toroman";
  src: url("./assets/fonts/toroman.ttf") format("truetype") 
}

@font-face { 
  font-family: "KiwiMaru-Regular";
  src: url("./assets/fonts/KiwiMaru-Regular.ttf") format("truetype") 
}

@font-face { 
  font-family: "HachiMaruPop-Regular";
  src: url("./assets/fonts/HachiMaruPop-Regular.ttf") format("truetype") 
}

.twitter-timeline-rendered {
  background-color: #ffff33dd;
}

.twitter-tweet-rendered {
  /* background-color: #ffff33dd; */
  margin-bottom: 0;
}

.x-typing a {
  color: #00ffb7
}

.markdown {
  color: #ffffff;
  padding-left: 5vw;
  padding-right: 5vw;
  text-align: left;
}

body {
  user-select: none;
  font-family: "KiwiMaru-Regular";
}

.userSelectEnableText {
  user-select: text;
}

a {
  color: #00ffb7;
  text-decoration: dotted;
  user-select: none;
}

/* .markdown H2, H3 {
  color: #acacacee;
} */

.markdown ol, ul {
  /* margin-left: -1vw; */
  padding-top: 1dvh;
}

.markdown li {
  padding-bottom: 1dvh;
}

.markdown a {
  color: #00ffb7;
}

.display-baba-n {
  /* width: 30vw;
  height: 30vh; */
  /* position: absolute; */
  /* background: #63a4ff; */
  /* display: block; */
  /* content: ""; */
  /* border-radius: 9999px; */
  /* opacity: 1; */
  animation: 500ms linear 1 forwards display-baba-n;
}

.satellite-circle {
  display: flex;
  justify-content: center;
  border: transparent;
  cursor: pointer;
}

@media (orientation: landscape) {
  .satellite-circle {
    width: 18dvh;
    height: 18dvh;
    border-radius: 8.7dvh;
    border-top-left-radius: 8.2dvh;
  }
}
@media (orientation: portrait) {
  .satellite-circle {
    width: 18vw;
    height: 18vw;
    border-radius: 8.7vw;
    border-top-left-radius: 8.2vw;
  }
}

@keyframes display-baba-n {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  80% {
    transform: scale(1.2);
    opacity: 1;
  }
  85% {
    transform: scale(0.9);
    opacity: 1;
  }
  90% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.display-jinwari {
  animation: 300ms linear 1 forwards display-jinwari;
}

@keyframes display-jinwari {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0.375;
  }
  100% {
    opacity: 1;
  }
}

.pan-left {
  animation: 300ms linear 1 forwards pan-left;
}

@keyframes pan-left {
  0% {
    transform:translate(50vw, 0);
  }
  100% {
    transform:translate(0, 0);
  }
}

.pan-right {
  animation: 300ms linear 1 forwards pan-right;
}

@keyframes pan-right {
  0% {
    transform:translate(-50vw, 0);
  }
  100% {
    transform:translate(0, 0);
  }
}

.element-anime-a {
  animation: 300ms linear 1 forwards display-jinwari;
}

.element-anime-a[data-isdisplayed="false"] {
  /* padding-top: 0px; */
  /* height: 0px; */
  animation: 500ms linear 1 forwards display-off;
}

@keyframes display-off {
  0% {
    display: block;
    /* transform: rotate3d(1, 1, 1, 45deg); */
    opacity: 1;
  }
  100% {
    display: none;
    transform: rotate3d(0, 1, 0.7, 360deg);
    opacity: 0;
  }
}
